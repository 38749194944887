import { MAX_TIME } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import { mergeLocalWithApi } from "@/app/util.mjs";
import type { ApexPlayer } from "@/data-models/apex-player.mjs";
import { ApexPlayerModel } from "@/data-models/apex-player.mjs";
import * as API from "@/game-apex/api.mjs";

export default async function getProfile(
  profileId: string,
  _searchParam?: URLSearchParams,
  state?: RouteState<{ isUpdate?: boolean }>,
  options?: Record<string, unknown>,
): Promise<ApexPlayer> {
  const player = await getData(
    API.getPlayer({ platformId: profileId }),
    ApexPlayerModel,
    ["apex", "profiles", profileId],
    {
      shouldFetchIfPathExists: !!state?.isUpdate,
      expiryTime: MAX_TIME,
      mergeFn: mergeLocalWithApi,
      ...options,
    },
  );
  return player;
}
