import type { RouteState } from "@/__main__/router.mjs";
import { getSeasons } from "@/game-apex/apex-fetch-static.mjs";
import GameMode from "@/game-apex/constants/game-modes.mjs";
import getMatches from "@/game-apex/fetches/get-matches.mjs";
import getMatchlist from "@/game-apex/fetches/get-matchlist.mjs";
import getProfile from "@/game-apex/fetches/get-profile.mjs";
import playerLegendsStats from "@/game-apex/fetches/player-legends-stats.mjs";
import playerSeasonStats from "@/game-apex/fetches/player-season-stats.mjs";
import playerWeaponStats from "@/game-apex/fetches/player-weapon-stats.mjs";
import {
  getCurrentSeason,
  getRankedModeByGameMode,
} from "@/game-apex/utils.mjs";
import { devError, devWarn } from "@/util/dev.mjs";

export default async function fetchData(
  [profileIdRouteParam, tabRouteParam]: FixedLengthArray<string, 2>,
  searchParam: URLSearchParams,
  state: RouteState<{ page?: number; isUpdate?: boolean }>,
) {
  const profileId = profileIdRouteParam || searchParam.get("profileId");
  const tab = tabRouteParam || searchParam.get("tab");
  const profile = await getProfile(profileId, undefined, state);
  // Blitz ID is pretty much used for all of our fetch requests instead of using profileId like our other games
  // Wtf is this shit, why are we querying the BlitzId then assigning the result into a Record<profileId, data>??
  const blitzId = profile?.id;
  if (!profile || !blitzId) return;
  // Fetch seasons
  const seasons = await getSeasons({
    shouldFetchIfPathExists: false,
  });
  // Init default params then reassign if custom params are set
  const paramsForStats = {
    season: "ALL",
    mode: "ALL",
  };
  for (const key in paramsForStats) {
    const value = searchParam.get(key);
    if (value) paramsForStats[key] = value;
  }
  // Local constants
  const seasonId = seasons[paramsForStats.season]?.id;
  const currentSeason = getCurrentSeason(seasons);
  const promises = [];
  const isEssential = !tab || tab === "in-game";
  // Player matchlist + matches
  if (isEssential) {
    promises.push(
      getMatchlist({
        blitzId,
        profileId,
        seasonId,
        season: paramsForStats.season,
        mode: paramsForStats.mode,
        page: state.page ?? 0,
      }),
    );
  }
  // Player legend stats
  if (isEssential || tab === "legends")
    promises.push(
      playerLegendsStats({ blitzId, profileId, seasonId, ...paramsForStats }),
    );
  // Player weapon stats
  if (isEssential || tab === "weapons")
    promises.push(
      playerWeaponStats({ blitzId, profileId, seasonId, ...paramsForStats }),
    );
  // Player season stats
  promises.push(
    getPlayerFullSeasonStats({
      blitzId,
      profileId,
      seasonId,
      ...paramsForStats,
    }),
  );
  const rankedMode = getRankedModeByGameMode(paramsForStats.mode);
  if (rankedMode && rankedMode === paramsForStats.mode) {
    for (const season of Object.values(seasons)) {
      if (paramsForStats.season === season.apexId) continue;
      promises.push(
        playerSeasonStats(
          {
            blitzId,
            profileId,
            seasonId: season.id,
            mode: rankedMode,
            season: season.apexId || "ALL",
          },
          currentSeason?.apexId === season.apexId,
        ).catch((e) => {
          devWarn(
            `Failed to fetch Apex player season stat for season: ${season.id}, mode: ${rankedMode}. This is could be a false posititve since we attempt to fetch all season stats for a player`,
            e,
          );
        }),
      );
    }
  }
  // Transient fetching
  promises.push(
    // Transient matches
    (async () => {
      const visibleMatches = Object.entries(
        state.transient?.visibleMatches ?? {},
      )
        .filter(([_, isVisible]) => isVisible)
        // fullId represents game id + season id, which needs to be deconstructed
        .map(([fullId]) => fullId.split("|"))
        .filter((i) => i.every((j) => j)) as Array<[string, string]>;
      await getMatches(visibleMatches, true);
    })(),
    // Transient players
    (async () => {
      const visibleMatchTilePlayers = Object.entries(
        state.transient?.matchTilePlayers ?? {},
      )
        .filter(([_, isVisible]) => isVisible)
        .map(([profileId]) => profileId);
      await Promise.all(
        visibleMatchTilePlayers.map((i) => getProfile(i, undefined, state)),
      );
    })(),
  );
  try {
    await Promise.all(promises);
  } catch (e) {
    devError("Failed to fetch profile page for Apex", e);
  }
}

async function getPlayerFullSeasonStats(props: {
  blitzId: string;
  profileId: string;
  seasonId: string;
  mode: string;
  season: string;
}) {
  try {
    await playerSeasonStats(props);
    if (props.mode !== GameMode.RANKED)
      await getPlayerFullSeasonStats({ ...props, mode: GameMode.RANKED });
  } catch (e) {
    if (e instanceof Error && e.message.includes("no records found")) return [];
    devError(
      `Failed to fetch Apex player season stat for profile ${props.profileId}, mode ${props.mode}`,
      e,
    );
  }
}
