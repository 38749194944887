import getData from "@/__main__/get-data.mjs";
import { mergeLocalWithApi } from "@/app/util.mjs";
import type { ApexPlayerStats } from "@/data-models/apex-player-stats.mjs";
import { ApexPlayerStatsModel } from "@/data-models/apex-player-stats.mjs";
import * as API from "@/game-apex/api.mjs";
import { APEX_PLAYER_BACK_OFF } from "@/game-apex/constants/constant-network.mjs";

export default async function playerSeasonStats(
  {
    blitzId,
    profileId,
    seasonId,
    mode,
    season,
  }: {
    blitzId: string;
    profileId: string;
    seasonId: string;
    mode: string;
    season: string;
  },
  shouldFetchIfPathExists: boolean = false,
): Promise<ApexPlayerStats> {
  const result = await getData(
    API.getPlayerSeasonStats({
      gameMode: mode,
      platformProfileId: blitzId, // This world is crazier than I thought
      seasonId,
    }),
    ApexPlayerStatsModel,
    ["apex", "playerStats", profileId, season, mode],
    {
      shouldFetchIfPathExists,
      networkBackOffTime: APEX_PLAYER_BACK_OFF,
      skipSafetyCheck: true,
      mergeFn: mergeLocalWithApi,
    },
  );
  return result;
}
