import { readState } from "@/__main__/app-state.mjs";
import { MAX_TIME } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import type { ApexMatch } from "@/data-models/apex-match.mjs";
import { ApexMatchModel } from "@/data-models/apex-match.mjs";
import * as API from "@/game-apex/api.mjs";
import { APEX_MATCH_BACK_OFF } from "@/game-apex/constants/constant-network.mjs";
import { devError } from "@/util/dev.mjs";
import { filterErrState } from "@/util/eval-state.mjs";

// This was documented in gaming news as the longest Apex match proven to have played by the community
const LONGEST_APEX_MATCH = 1000 * 60 * 60 * 3;

export default async function getMatches(
  gameIds: Array<[string, string]>,
  isAsync = false,
): Promise<void> {
  if (isAsync) {
    try {
      await Promise.all(
        gameIds.map(([gameId, seasonId]) => getMatch(gameId, seasonId)),
      );
    } catch (e) {
      if (e instanceof Error && e.message.includes("no records found")) return;
      devError("Failed to async fetch matches", e);
    }
    return;
  }
  for (const [gameId, seasonId] of gameIds) {
    try {
      await getMatch(gameId, seasonId);
    } catch (e) {
      devError(`Failed to fetch match ${gameId}`, e);
    }
  }
}

async function getMatch(gameId: string, seasonId: string): Promise<ApexMatch> {
  const options: {
    networkBackOffTime?: number;
    skipSafetyCheck: boolean;
    shouldFetchIfPathExists?: boolean;
    expiryTime?: number;
  } = {
    skipSafetyCheck: true,
  };
  // Prevent match fetching if its fully complete and is already in state
  const match = filterErrState<ApexMatch>(readState.apex.matches[gameId]);
  if (
    (match?.gameStartedAt ?? Number.MAX_SAFE_INTEGER) >
    Date.now() - LONGEST_APEX_MATCH
  ) {
    // Allow users to be able to keep fetching this if the match is not yet complete
    options.networkBackOffTime = APEX_MATCH_BACK_OFF;
  } else {
    // This is a network optimization
    // Prevent spamming servers if this is already in state and the game is guaranteed finished
    options.shouldFetchIfPathExists = false;
    options.networkBackOffTime = MAX_TIME;
    options.expiryTime = MAX_TIME;
  }
  const result = await getData(
    API.getMatch({
      seasonId,
      apexId: gameId,
    }),
    ApexMatchModel,
    ["apex", "matches", gameId],
    options,
  );
  return result;
}
