import getData from "@/__main__/get-data.mjs";
import { mergeNestedObjWithApi } from "@/app/util.mjs";
import { ApexPlayerWeaponStatsModel } from "@/data-models/apex-player-weapon-stats.mjs";
import * as API from "@/game-apex/api.mjs";
import { APEX_PLAYER_BACK_OFF } from "@/game-apex/constants/constant-network.mjs";

export default async function playerWeaponStats(
  {
    blitzId,
    profileId,
    seasonId,
    mode,
    season,
  }: {
    blitzId: string;
    profileId: string;
    seasonId: string;
    mode: string;
    season: string;
  },
  shouldFetchIfPathExists: boolean = false,
): Promise<void> {
  await getData(
    API.getPlayerWeaponSeasonStats({
      gameMode: mode,
      platformProfileId: blitzId, // If I had 1 wish, it would be to destroy this
      seasonId,
    }),
    ApexPlayerWeaponStatsModel,
    ["apex", "playerWeaponStats", profileId, season, mode],
    {
      shouldFetchIfPathExists,
      networkBackOffTime: APEX_PLAYER_BACK_OFF,
      mergeFn: mergeNestedObjWithApi,
    },
  );
}
