import getData from "@/__main__/get-data.mjs";
import { mergeNestedObjWithApi } from "@/app/util.mjs";
import {
  ApexPlayerChampionSeasonStatsInput,
  ApexPlayerLegendStatsModel,
} from "@/data-models/apex-player-legend-stats.mjs";
import * as API from "@/game-apex/api.mjs";
import { APEX_PLAYER_BACK_OFF } from "@/game-apex/constants/constant-network.mjs";
import GameMode from "@/game-apex/constants/game-modes.mjs";

export default async function playerLegendsStats(
  {
    blitzId,
    profileId,
    seasonId,
    mode,
    season,
  }: {
    blitzId: string;
    profileId: string;
    seasonId: string;
    mode: string;
    season: string;
  },
  shouldFetchIfPathExists: boolean = false,
): Promise<void> {
  await getData(
    API.getPlayerChampionSeasonStats(
      ApexPlayerChampionSeasonStatsInput({
        gameMode: GameMode[mode] ?? GameMode.ALL,
        platformProfileId: blitzId, // This is wild that this isn't profile id
        seasonId,
      }),
    ),
    ApexPlayerLegendStatsModel,
    ["apex", "playerLegendStats", profileId, season, mode],
    {
      shouldFetchIfPathExists,
      networkBackOffTime: APEX_PLAYER_BACK_OFF,
      mergeFn: mergeNestedObjWithApi,
    },
  );
}
